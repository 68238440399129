import React, { Suspense, useRef, MutableRefObject } from "react";

import { Canvas } from "@react-three/fiber";
import Model from "./Uno";
import * as THREE from "three";

type props = {
  modelScale: MutableRefObject<Array<number>>;
};

const Visuals = React.memo(({ modelScale }: props): JSX.Element => {
  const mesh = useRef<THREE.Mesh>();

  return (
    <Canvas
      style={{
        height: "100%",
        background: "url('background.png') no-repeat center center",
        backgroundSize: "cover",
      }}
      camera={{
        position: [10.2, 0, 0.25],
        rotation: [5, 1.5, 1],
      }}
    >
      <mesh ref={mesh} scale={[0.8, 0.8, 0.8]}>
        <Suspense fallback={null}>
          <pointLight intensity={1} position={[10, 20, 10]} />
          <Model modelScale={modelScale} />
        </Suspense>
      </mesh>
    </Canvas>
  );
});

export default Visuals;
