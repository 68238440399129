import React, { useState, useEffect, useCallback, useRef } from "react";

import { Message, AudioWithTime } from "../types";
import { config_var } from "./config";

// deps
import { v4 as uuidv4 } from "uuid";

type CaptainHook = {
  getIdleAudio: (history: Array<Message>) => Promise<AudioWithTime | null>;
  sendMessage: (
    msg: string,
    history: Array<Message>,
    removeBlocked: React.Dispatch<React.SetStateAction<Message[]>>
  ) => void;
  lastMessage: Message;
  lastAudio: AudioWithTime;
  error: string;
  isFetching: boolean;
  persona: string;
  setPersona: (persona: string) => void;
  personas: Array<string>;
};

const SESSION_ID: string = config_var("SESSION_ID") || uuidv4();
let CONVERSATION_ID: string = uuidv4();
const PERSONA: string = config_var("PERSONA");

if (PERSONA === undefined) throw Error("Missing enrionment variable PERSONA");

const CAPTAIN_URL = config_var("CAPTAIN_URL");
if (CAPTAIN_URL === undefined)
  throw Error("Missing enrionment variable CAPTAIN_URL");

const PERSONA_SELECT: boolean = !!config_var("PERSONA_SELECT");
const SKIP_INIT_MESSAGE: boolean = !!config_var("SKIP_INIT_MESSAGE");
const COMMAND_REGEX: RegExp = /^\<.*\>$/;

export const useCaptain = (
  gotFirstUserAction: boolean,
  setMicrophoneMuted: any,
  clearIdleSpeakInterval: any,
  createIdleSpeakInterval: any,
  clearHistory: any,
  resetTimer: any
): CaptainHook => {
  const [lastMessage, setLastMessage] = useState<Message | null>(null);
  const [lastAudio, setLastAudio] = useState<AudioWithTime | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [persona, setPersona] = useState<string>(PERSONA);
  const [error, setError] = useState<string | null>(null);
  const [personas, setPersonas] = useState<Array<string>>([]);

  useEffect(() => {
    // fetch persona list if PERSONA_SELECT env set
    if (!PERSONA_SELECT) {
      return;
    }
    fetch(`${CAPTAIN_URL}/personas`)
      .then((r) => r.json())
      .then(setPersonas);
  }, []);

  const postToCaptain = async function (
    text: string,
    history: Message[]
  ): Promise<any> {
    // async function - just does the Captain API call and resturns the request
    // (without writing any internal state anywhere)

    // do post request
    let r = await fetch(`${CAPTAIN_URL}/personas/${persona}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query_text: text,
        session_id: SESSION_ID,
        conversation_id: CONVERSATION_ID,
        history: history,
      }),
    });

    return r;
  };

  const handleCommand = function (response: any): boolean {
    let should_say = false;
    if ("response_command" in response && response["response_command"]) {
      console.debug(`Handling command "${response["response_command"]}"`);
      switch (response["response_command"]) {
        case "START":
          console.log("Starting conversation");
          setMicrophoneMuted(false);
          clearIdleSpeakInterval();
          should_say = true;
          break;
        case "MUTE_MIC":
          setMicrophoneMuted(true);
          createIdleSpeakInterval();
          break;
        case "CLEAR_HISTORY":
          clearHistory();
          setMicrophoneMuted(true);
          createIdleSpeakInterval();
          break;
        case "SOFT_RELOAD":
          setMicrophoneMuted(true);
          createIdleSpeakInterval();
          setTimeout(() => {
            //resetTimer();
            clearHistory();
          }, 5000);
          CONVERSATION_ID = uuidv4();
          break;
        case "HARD_RELOAD":
          window.location.reload();
          break;
      }
    }
    return should_say;
  };

  const getIdleAudio = async function (
    history: Array<Message>
  ): Promise<AudioWithTime | null> {
    // send an empty query, return the audio response
    const response = await postToCaptain("<IDLE>", history);

    if (
      !response.ok ||
      response.headers.get("content-type") != "application/json"
    )
      return;

    const data = await response.json();

    let should_show = handleCommand(data);

    if (should_show) {
      setLastMessage({
        text: data["response_text"],
        time: new Date().getTime(),
        sender: "AI",
      });
    }

    if (data["response_text"]) {
      return {
        url: `${CAPTAIN_URL}/speech/${data["response_audio"]}`,
        time: new Date(),
      };
    } else return null;
  };

  const sendMessage = useCallback(
    (
      text: string,
      history: Array<Message>,
      setHistory: React.Dispatch<React.SetStateAction<Message[]>>
    ): void => {
      // Send request to captain
      if (isFetching) {
        setError("Attempted concurrent requests..");
        return;
      }

      setIsFetching(true);
      postToCaptain(text, history)
        .then((response) => {
          setIsFetching(false);
          if (!response.ok) {
            setError(`Fetching failed, got response code ${response.status}`);
            throw Error("Got non-200 error code from captain");
          } else if (
            response.headers.get("content-type") != "application/json"
          ) {
            setError(
              `Fetching failed, got content header ${response.headers.get(
                "content-type"
              )}`
            );
            throw Error(
              'Got non-"application/json" content-header from captain'
            );
          } else {
            setError(null); // No error
            return response.json();
          }
        })
        .then((data) => {
          handleCommand(data);

          // If response text is "" it means dont show it. No reponse.
          if (data["response_text"]) {
            // If a question was blocked by the backend
            // we remove it by resetting the history
            if (data["blocked"]) {
              console.log("Message blocked by backend, removing it");
              setHistory(history);
            }

            setLastMessage({
              text: data["response_text"],
              time: new Date().getTime(),
              sender: "AI",
            });

            setLastAudio({
              url: `${CAPTAIN_URL}/speech/${data["response_audio"]}`,
              time: new Date(),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setError("Request to captain failed");
        });
    },
    [isFetching, persona]
  );

  useEffect(() => {
    // Get initial prompt
    // empty message means give me initial prompt
    if (gotFirstUserAction && !SKIP_INIT_MESSAGE)
      setTimeout(() => sendMessage("", [], (msgs) => msgs), 100);
  }, [gotFirstUserAction]);

  return {
    getIdleAudio,
    sendMessage,
    lastMessage,
    lastAudio,
    error,
    isFetching,
    persona,
    setPersona,
    personas,
  };
};

export default useCaptain;
