
// react
import { useState, useRef, useEffect, useCallback } from "react";
import "./App.css";

// app
import AudioPlayer from "./components/audio-player";
import ChatView from "./components/chat-view";
import useSpeechToText from "./lib/speech-to-text";
import Visuals from "./components/visuals";
import useCaptain from "./lib/captain";
import { AudioWithTime, Message } from "./types";
import { config_var } from "./lib/config";

// Deps
// 100vh fix component for mobile
import Div100vh from "react-div-100vh";
import InfoPopup from "./components/info-popup";
import ArosInfoPopup from "./components/aros-info-popup";
import ArtSelector from "./components/aros-art-selector";
import CountDownPopup from "./components/countdown-popup";
import ShadowInfoPopup from "./components/shadow-info-popup";

window.Buffer = window.Buffer || require("buffer").Buffer;
window.process = window.process || require("process");

// true if envvar set - start unmuted
const START_UNMUTED: boolean = !!config_var("START_UNMUTED");
const DISABLE_VISUALS: boolean = !!config_var("DISABLE_VISUALS");
const AROS_VISUALS: boolean = !!config_var("AROS_VISUALS");
const SHADOW_VISUALS: boolean = !!config_var("SHADOW_VISUALS");
const IDLE_TIMEOUT_SECONDS: number | undefined =
  config_var("IDLE_TIMEOUT_SECONDS") && +config_var("IDLE_TIMEOUT_SECONDS");
const IDLE_SOUNDS_INTERVAL_SECONDS: number | undefined =
  config_var("IDLE_SOUNDS_INTERVAL_SECONDS") &&
  +config_var("IDLE_SOUNDS_INTERVAL_SECONDS");
const SKIP_POPUP: boolean = !!config_var("SKIP_POPUP");

let reloadTimer: any = undefined;

const App = (): JSX.Element => {
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [gotFirstUserAction, setGotFirstUserAction] = useState(false);
  const modelScale = useRef<Array<number>>([1, 1, 1]);
  const [audio, setAudio] = useState<AudioWithTime | null>(null);
  const [microphoneMuted, setMicrophoneMuted] = useState(!START_UNMUTED);
  const [isTalking, setIsTalking] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [activateIdleSpeakInterval, setActivateIdleSpeakInterval] =
    useState<boolean>(false);

  const resetTimer = () => {
    console.log(`Resetting cockpit timeout to ${IDLE_TIMEOUT_SECONDS} seconds`);
    clearTimeout(reloadTimer);
    reloadTimer = setTimeout(() => {
      handleNewUserMessage(messages, "<TIMEOUT>");
    }, IDLE_TIMEOUT_SECONDS * 1000);
  };

  const clearIdleSpeakInterval = (): void => {
    // https://stackoverflow.com/questions/8635502/how-do-i-clear-all-intervals
    // Get new interval (will ALWAYS be higer than all previous intervals)
    const interval_id = window.setInterval(function () {},
    Number.MAX_SAFE_INTEGER);

    console.debug(`Clearing all intervals from 0 through ${interval_id}`);

    // Clear any timeout/interval up to that id
    for (let i = 0; i <= interval_id; i++) {
      if (i != reloadTimer) {
        window.clearInterval(i);
      }
    }
  };

  useEffect(() => {
    // don't do idle audio stuff if it's not configured
    if (!IDLE_SOUNDS_INTERVAL_SECONDS) return;

    setMessages((msgs: Array<Message>) => msgs);
    captain.getIdleAudio(messages).then((audio) => {
      if (audio) {
        setAudio(audio);
      }
    });
  }, [activateIdleSpeakInterval]);

  const createIdleSpeakInterval = (): void => {
    // restart the idle speak interval

    // only do stuff if `IDLE_SOUNDS_INTERVAL_SECONDS`
    // if we try to setInterval with a falsey value, it will run at max speed
    // this was the cause of a pretty bad bug that killed captain on 9-10 Nov 2022
    if (!IDLE_SOUNDS_INTERVAL_SECONDS) return;

    clearIdleSpeakInterval();
    const interval_id = window.setInterval(
      () => setActivateIdleSpeakInterval((i: boolean) => !i),
      IDLE_SOUNDS_INTERVAL_SECONDS * 1000
    );
    console.debug(
      `Setting idle speak interval (${interval_id}) to ${IDLE_SOUNDS_INTERVAL_SECONDS} seconds`
    );
  };

  const captain = useCaptain(
    gotFirstUserAction,
    setMicrophoneMuted,
    () => clearIdleSpeakInterval(),
    () => createIdleSpeakInterval(),
    () => clearHistory(),
    () => resetTimer()
  );

  const clearHistory = () => {
    setMessages((): Array<Message> => []);
  };

  const handleNewUserMessage = (messages: Array<Message>, text: string) => {
    // make captain request
    captain.sendMessage(text, messages, setMessages);

    // create Message obj
    const msg: Message = {
      time: new Date().getTime(),
      text: text,
      // It's importantant that this says You, as some css depends on it..
      sender: "You",
    };

    // add message to the history *after* sending
    setMessages([...messages, msg]);

    // reset reload timer if configured
    if (IDLE_TIMEOUT_SECONDS) {
      console.log("Cockpit timeout reset because of user input");
      resetTimer();
    }
  };

  const stt = useSpeechToText(
    microphoneMuted || isTalking,
    () => {
      if (IDLE_TIMEOUT_SECONDS) {
        resetTimer();
      }
    },
    () => clearTimeout(reloadTimer)
  );

  useEffect(() => {
    // start idle speaking interval as soon as the idle screen is displayed
    // called on component mount
    if (IDLE_SOUNDS_INTERVAL_SECONDS) {
      createIdleSpeakInterval();
    }
    // if (IDLE_TIMEOUT_SECONDS) {
    // resetTimer();
    // }
  }, []);

  useEffect(() => {
    if (SKIP_POPUP) {
      firstUserAction();
    }
  }, []);

  const onUserMessage = useCallback(
    (text: string): void => {
      // Called when we have a msg from a user, either from chat view or speech-to-text

      // cancel idle-speaking interval (since we're no longer idle)
      clearIdleSpeakInterval();

      handleNewUserMessage(messages, text);
    },
    [captain, messages]
  );

  useEffect(() => {
    // Called when we get a speech-to-text result
    if (stt.lastSTTResult !== null) {
      onUserMessage(stt.lastSTTResult);
    }
  }, [stt.lastSTTResult]);

  useEffect(() => {
    // Update audio when captain gets new audio
    if (captain.lastAudio != null) setAudio(captain.lastAudio);
  }, [captain.lastAudio]);

  // Called when we get a response from captain
  useEffect(() => {
    if (captain.lastMessage !== null) {
      setMessages([...messages, captain.lastMessage]);
    }
  }, [captain.lastMessage]);

  const firstUserAction = () => {
    // Needed to initialize microphone read and audio playing
    stt.init();
    // Play silent audio, so the audio player gets "playing permission"
    setAudio({ url: "silent.mp3", time: new Date() });
  };

  useEffect(() => {
    if (stt.initialized) setGotFirstUserAction(true);
  }, [stt.initialized]);

  const renderMain = () => {
    if (!gotFirstUserAction && AROS_VISUALS) {
      return <ArosInfoPopup onClick={firstUserAction} />;
    } else if (!gotFirstUserAction && SHADOW_VISUALS) {
      return <ShadowInfoPopup onClick={firstUserAction} />;
    } else if (!gotFirstUserAction) {
      return <InfoPopup onClick={firstUserAction} />;
    } else {
      return (
        <Div100vh
          style={{
            overflow: "hidden",
            position: "relative",
            ...(AROS_VISUALS ? { backgroundColor: "black" } : {}),
          }}
        >
          {AROS_VISUALS || DISABLE_VISUALS ? (
            ""
          ) : (
            <Visuals modelScale={modelScale} />
          )}
          <ChatView
            messages={messages}
            onText={onUserMessage}
            hide={false}
            enabled={true}
            onMicrophoneMute={() => setMicrophoneMuted((m) => !m)}
            onAudioMute={() => setAudioMuted((m) => !m)}
            audioMuted={audioMuted}
            microphoneMuted={microphoneMuted}
            personas={captain.personas}
            persona={captain.persona}
            onPersonaChange={captain.setPersona}
            arosView={AROS_VISUALS}
          />
          {AROS_VISUALS ? (
            <ArtSelector
              sendMessage={(text: string) => {
                onUserMessage(text);
              }}
            ></ArtSelector>
          ) : (
            ""
          )}
        </Div100vh>
      );
    }
  };

  return (
    <>
      <AudioPlayer
        setIsTalking={setIsTalking}
        muted={audioMuted}
        audio={audio}
        modelScale={modelScale}
      />
      <CountDownPopup
        stopEverything={() => {
          clearTimeout(reloadTimer);
          console.log("Reload timer cleared due to downtime");
          clearIdleSpeakInterval();
          console.log("Idle speak interval cleared due to downtime");
          setMicrophoneMuted(true);
          console.log("Microphone muted due to downtime");
          setAudioMuted(true);
          console.log("Audio muted due to downtime");
        }}
      ></CountDownPopup>
      {renderMain()}
    </>
  );
};
export default App;
