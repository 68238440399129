import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { config_var } from "./lib/config";

const SENTRY_URL: string | undefined = config_var("SENTRY_URL");

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: config_var("SENTRY_ENVIRONMENT"),
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
