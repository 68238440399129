import { useState, useEffect } from "react";
import "reactjs-popup/dist/index.css";
import { config_var } from "../lib/config";

const ART_SERVICE_URL = config_var("AROS_ART_SERVICE_URL");
const AROS_VISUALS: boolean = !!config_var("AROS_VISUALS");

// IMPORTANT bc this module is always imported by App
if (AROS_VISUALS) {
  require("./aros-art-selector.css");
}

type Props = {
  sendMessage: (text: string) => void;
};

interface Artwork {
  title: string;
  artist: string;
  year: string;
  description: string;
  src: string;
}

export default function ArtSelector(props: Props) {
  const [artworks, setArtworks] = useState<Array<Artwork>>([]);

  useEffect(() => {
    fetch(`${ART_SERVICE_URL}/images`)
      .then((r) => r.json())
      .then((j) => setArtworks(j))
      .catch(() => alert(`Failed getting artworks from ${ART_SERVICE_URL}`));
  }, []);

  const imgClicked = (artwork: Artwork) => {
    // send something to captain
    const message: string = `Let's talk about the artwork ${artwork.title} by ${artwork.artist}. Describe the artwork for me.`;
    props.sendMessage(message);
  };

  return (
    <div className="aros-art-selector-wrapper">
      <h2>{"Talk to the AI about art"}</h2>
      <div className="aros-art-selector">
        <div className="aros-art-images">
          {artworks.map((a) => {
            return (
              <div key={a.title} className="row-wrapper">
                <div className="aros-art-image">
                  <img
                    src={`${ART_SERVICE_URL}/${a.src}`}
                    alt={a.description}
                    id={a.title}
                    onClick={(_) => imgClicked(a)}
                  />
                  <label htmlFor={a.title}>{a.title}</label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
