import React, { useEffect } from "react";
import "reactjs-popup/dist/index.css";
import "./popups.css";
import "./info-popup.css";
import Div100vh from "react-div-100vh";
import { config_var } from "../lib/config";

const INFO_POPUP_HEADER =
  config_var("INFO_POPUP_HEADER") || "Cockpit Captain Frontend";

type Props = { onClick: () => void };

export default function InfoPopup(props: Props) {
  useEffect(() => {
    document.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        props.onClick();
      }
    });
  }, []);

  return (
    <Div100vh
      className="global"
      style={{
        backgroundImage: "url('/background.png')",
      }}
    >
      <h1 className="ai-sky">{INFO_POPUP_HEADER}</h1>
      <button
        className="enter-button"
        type="button"
        onClick={() => {
          props.onClick();
        }}
      >
        Enter
      </button>
    </Div100vh>
  );
}
