import React, { useRef, useEffect, MutableRefObject, useCallback } from "react";
import { AudioWithTime } from "../types";

type props = {
  audio: AudioWithTime;
  setIsTalking: (isTaling: boolean) => void;
  modelScale: MutableRefObject<Array<number>>;
  muted: boolean;
};

export const AudioPlayer = ({
  audio,
  modelScale,
  setIsTalking,
  muted,
}: props): JSX.Element => {
  const player = useRef(null);
  const analyser = useRef(null);
  const source = useRef(null);
  const audioContext = useRef(null);

  const drawFrame = useCallback(() => {
    const reqAnimationFrame = window.requestAnimationFrame;
    var dataArray = new Uint8Array(analyser.current.frequencyBinCount);
    analyser.current.getByteFrequencyData(dataArray);
    const sum = dataArray.reduce((acc, curr) => acc + curr, 0);
    const avg = sum / dataArray.length;
    const scale = 1 + avg / (255 * 4);
    modelScale.current = [scale, scale, scale];
    reqAnimationFrame(drawFrame);
  }, [modelScale]);

  const init = useCallback((): void => {
    // Initialize animator thingy
    // Analyser for animation stuff
    // it's called webkitAudioContext on iOS ¯\_()_/¯
    // (window as any) because typescipt doesn't understand that people are different
    // and with people i mean browsers
    const AudioContext =
      window.AudioContext || (window as any).webkitAudioContext;
    audioContext.current = new AudioContext();

    source.current = audioContext.current.createMediaElementSource(
      player.current
    );
    analyser.current = audioContext.current.createAnalyser();
    analyser.current.fftSize = 2048;

    source.current.connect(analyser.current);
    source.current.connect(audioContext.current.destination);
    drawFrame();
  }, [drawFrame]);

  useEffect(() => {
    if (player?.current !== null) player.current.muted = muted;
    // if (player.current.muted !== muted) {
    //   console.log(`mute set to ${muted}`)
    //   source.current.disconnect(audioContext.current.destination)
    // } else {
    //   source.current.connect(audioContext.current.destination)
    // }
  }, [muted]);

  useEffect(() => {
    if (player?.current !== null && audio !== null) {
      if (source.current == null) init();

      // Load new audio
      player.current.src = audio.url;
      // Play
      player.current.play();
    }
  }, [audio, init]);

  if (audio !== null)
    //return <><audio style={{display: "none"}} crossOrigin={"anonymous"} controls ref={player}/><button onClick={init}>init audiocontext stuff</button></>
    return (
      <audio
        onPlaying={() => setIsTalking(true)}
        onEnded={() => setIsTalking(false)}
        style={{ display: "none" }}
        crossOrigin={"anonymous"}
        controls
        ref={player}
      />
    );
  else return <></>;
};

export default AudioPlayer;
