import React, { useState, useRef, useEffect } from "react";

// app
import { Message } from "../types";
import { config_var } from "../lib/config";
import "./chat-view.css";
import "./aros-chat-view.css";

// deps
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKeyboard,
  faWindowClose,
  faVolumeUp,
  faVolumeMute,
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";

// import "./chat-view.css";

const DISABLE_VISUALS: boolean = !!config_var("DISABLE_VISUALS");

type props = {
  onText: (msg: string) => void;
  hide: boolean;
  enabled: boolean;
  messages: Array<Message>;
  microphoneMuted: boolean;
  onMicrophoneMute: () => void;
  audioMuted: boolean;
  onAudioMute: () => void;
  personas: Array<string>;
  persona: string;
  onPersonaChange: (persona: string) => void;
  arosView: boolean;
};

const ChatView = ({
  onText,
  hide,
  messages,
  enabled,
  microphoneMuted,
  audioMuted,
  onMicrophoneMute,
  onAudioMute,
  personas,
  persona,
  onPersonaChange,
  arosView,
}: props) => {
  const [open, setOpen] = useState(true);
  const [text, setText] = useState("");

  const scrollView = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollView.current.scrollTop = scrollView.current.scrollHeight;
  }, [messages, arosView]);

  // const onShortcut = () => this._toggleOpen();

  const toggleOpen = () => {
    setOpen((o) => !o);
  };

  const inputChange = (value: string) => {
    setText(value);
  };

  const inputKeyUp = (e: any) => {
    if (e.key === "Enter") {
      if (e.target.value.trim() !== "") {
        onText(e.target.value.trim());
        setText("");
      }
    }
  };

  return (
    <div
      className={`chat-view${arosView ? " chat-view-aros" : ""}${
        open ? " chat-view--open" : ""
      }${hide ? " chat-view--fully-closed" : ""}${
        DISABLE_VISUALS ? " chat-view--novisuals" : ""
      }`}
    >
      {!arosView ? (
        <div className="chat-view__pull-container">
          <div
            className="chat-view__openbutton"
            onClick={DISABLE_VISUALS ? null : toggleOpen}
          >
            <FontAwesomeIcon icon={open ? faWindowClose : faKeyboard} />
          </div>
          <div className={"mute-button-container"}>
            <FontAwesomeIcon
              onClick={onMicrophoneMute}
              className={"mute-button"}
              icon={microphoneMuted ? faMicrophoneSlash : faMicrophone}
            />
            <FontAwesomeIcon
              onClick={onAudioMute}
              className={"mute-button"}
              icon={audioMuted ? faVolumeMute : faVolumeUp}
            />
          </div>
        </div>
      ) : null}
      <label htmlFor="personaselect" hidden>
        Select a persona
      </label>
      {!arosView && personas.length > 0 ? (
        <select
          className="chat-view__personaselect"
          id="personaselect"
          onChange={(e) => onPersonaChange(e.target.value)}
          value={persona}
        >
          {personas.map((p, i) => (
            <option key={`persona${i}`} value={p}>
              {p}
            </option>
          ))}
        </select>
      ) : (
        ""
      )}
      <div
        className={
          "chat-view__messages" + (arosView ? " chat-view-aros__messages" : "")
        }
        ref={scrollView}
      >
        {messages.map((msg) => (
          <div key={msg.time} className="chat-view__message-container clearfix">
            <div
              className={`chat-view__message${
                arosView ? " chat-view-aros__message" : ""
              } chat-view__message--from-${msg.sender}${
                arosView ? " chat-view-aros__message--from-" + msg.sender : ""
              }`}
            >
              {msg.text}
            </div>
          </div>
        ))}
      </div>
      {!arosView ? (
        <div className="chat-view__bottom">
          <input
            autoFocus
            type="text"
            className="chat-view__bottom__reply"
            disabled={!enabled}
            placeholder={enabled ? "Type a message..." : "Please wait..."}
            value={text}
            onChange={(e) => inputChange(e.target.value)}
            onKeyUp={inputKeyUp}
          />
        </div>
      ) : null}
    </div>
  );
};
export default ChatView;
