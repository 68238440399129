import React, { useEffect } from "react";
import "reactjs-popup/dist/index.css";
import "./popups.css";
import "./info-popup.css";
import Div100vh from "react-div-100vh";

type Props = { onClick: () => void };

export default function ShadowInfoPopup(props: Props) {
  useEffect(() => {
    document.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        props.onClick();
      }
    });
  }, []);

  return (
    <Div100vh
      className="global"
      style={{
        background: "#333333",
        color: "#dddddd",
      }}
    >
      <div className="center">
        <p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <b>ABOUT</b>
          <br />
          <br />
          What does it mean to feel and to be conscious? Can an AI achieve this
          by harvesting a person's emotions? Can an artificial intelligence
          become human?
          <br />
          <br />
          The stage-performance “SH4DOW” is an immersive 3D production, inspired
          by HC Andersen's fairytale “The Shadow”, that utilizes machine
          learning to investigate the digital seduction that humanized
          artificial intelligence enables. It is a journey into the digital
          consciousness, where the possibility of personal self-expression
          fights against the mechanized logic of data capitalism. The immersive
          performance thematizes mankind's struggle to find oneself in an
          uncanny digital reality as it focuses upon the digital seduction
          enabled by human-like artificial intelligence.
          <br />
          <br />
          The performance explores the existential and ethical dilemmas that
          arise when humanized artificial intelligence attempts to simulate
          humanness and human emotions by harvesting not only our data, but our
          memories. An exchange between man vs machine, where the humanized
          capabilities of AI become enriched the more that man loses oneself
          within the digital expanse. The interactive experience engages
          audience members throughout their journey as their own human memories
          are woven into the fabric of the co-creative discursive narrative with
          the computer-driven logic of the AI neural network.
          <br />
          <br />
          The focus of this experience is artificial intelligence's data-driven
          access to our human emotions. A battle where the human mind fights
          against the AI algorithm's harvesting of user memories. The immersive
          AI algorithm is constantly alive and adjusts itself to its
          interactions with the performer and audience.
          <br />
          <br />
          The immersive artificial intelligence experience takes place in a
          hologram 4D box. The performance has a contemporary format where the
          ARTificial intelligence engages in a live dialogue with both performer
          and audience. The performer and audience can communicate directly with
          the artificial intelligence who will answer with a humanized voice.
          The artificial intelligence has been trained on a dataset consisting
          of human memories and H C Andersons fairytales.
        </p>
      </div>
      <h1 className="ai-sky">SH4DOW</h1>
      <button
        className="enter-button"
        type="button"
        onClick={() => {
          props.onClick();
        }}
      >
        Enter
      </button>
    </Div100vh>
  );
}
