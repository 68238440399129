export function config_var(name: string): string | undefined {
  /* Get config var - either from query parameter or environment variable
   * query parameter takes precedence.
   * */

  const params = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );

  // if there's a matching query param, return it
  for (let param in params) {
    if (name.toLowerCase() === param.toLowerCase()) {
      return params[param];
    }
  }

  // otherwise: read env var from window._env
  const env: any = (window as any)?._env;
  const env_var: string | undefined = env[name] || env[`REACT_APP_${name}`];
  return env_var;
}
