import { createRef, useState, useEffect } from "react";
import { config_var } from "../lib/config";

import "./countdown-popup.css";

const timerRef = createRef<HTMLDivElement>();
const OPERATING_TIME_CRONSTR: string = config_var("OPERATING_TIME_CRONSTR");
const OPERATING_TIME_TIMEZONE: string = config_var("OPERATING_TIME_TIMEZONE");
const IMPLEMENTED_CRON_FORMAT: RegExp = /\* [0-9]+-[0-9]+ \* \* \*/;

let interval: NodeJS.Timer = null;

type props = {
  stopEverything: any;
};

const CountDownPopup = ({ stopEverything }: props) => {
  const [goUpTime, setGoUpTime] = useState<Date>();

  let dateNowWithEnvTimezone = (): Date => {
    return new Date(
      OPERATING_TIME_TIMEZONE
        ? new Date().toLocaleString("en-US", {
            timeZone: OPERATING_TIME_TIMEZONE,
          })
        : new Date().toLocaleString()
    );
  };

  let getNextHour = (hour: number): Date => {
    let nextHourDate = dateNowWithEnvTimezone();
    nextHourDate.setHours(hour);
    nextHourDate.setMinutes(0);
    nextHourDate.setSeconds(0);
    if (nextHourDate < dateNowWithEnvTimezone()) {
      nextHourDate = new Date(
        nextHourDate.getTime() + 24 * 60 * 60 * 1000 // + one day
      );
    }
    return nextHourDate;
  };

  // This could be done down at the return statement,
  // but since react calls components all the time
  // this logs more cleanly
  useEffect(() => {
    if (goUpTime) {
      console.log(`Currently within downtime, going live at ${goUpTime}`);
      stopEverything();
      if (!interval) {
        // Update the count down every 1 second
        interval = setInterval(function () {
          // Get today's date and time
          var now = new Date().getTime();

          // Find the distance between now and the count down date
          var distance = goUpTime.getTime() - now;

          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // Display the result in the element with id="demo"
          timerRef.current.innerHTML =
            days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

          if (distance < 0) {
            clearInterval(interval);
            timerRef.current.innerHTML = "Reloading page...";
            setTimeout(() => window.location.reload(), 5000);
          }
        }, 1000);
      }
    }
  }, [goUpTime]);

  useEffect(() => {
    if (OPERATING_TIME_CRONSTR) {
      if (IMPLEMENTED_CRON_FORMAT.exec(OPERATING_TIME_CRONSTR)) {
        let date = new Date(
          OPERATING_TIME_TIMEZONE
            ? new Date().toLocaleString("en-US", {
                timeZone: OPERATING_TIME_TIMEZONE,
              })
            : new Date().toLocaleString()
        );
        let cron: string[] = OPERATING_TIME_CRONSTR.split(" ");

        // WARNING: This is not implemented genericly
        let operatingHours = cron[1].split("-", 2).map((v) => Number(v));
        let nextUptime = getNextHour(operatingHours[0]);
        let nextDowntime = getNextHour(operatingHours[1]);

        if (nextUptime < nextDowntime) {
          setGoUpTime(nextUptime);
        } else {
          console.log(
            `Currently within operating time, going down at ${nextDowntime}`
          );
          setTimeout(() => {
            console.log("TEST!");
            setGoUpTime(getNextHour(operatingHours[0]));
          }, nextDowntime.getTime() - date.getTime());
        }
      } else {
        console.error(
          `CRON string not implemeted genericly, only CRON strings of type "${IMPLEMENTED_CRON_FORMAT}" allowed, sorry`
        );
      }
    } else {
      console.log("No operation time CRON string, running forever");
    }
  }, []);

  if (goUpTime) {
    return (
      <div className="countdown-bounding-box">
        <div className="countdown">
          Artwork outside operating time, starting in:
          <br />
          <br />
          <div ref={timerRef} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CountDownPopup;
