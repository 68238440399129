import "reactjs-popup/dist/index.css";
import "./aros-info-popup.css";

type Props = { onClick: () => void };

export default function ArosInfoPopup(props: Props) {
  return (
    <div
      className="global-aros"
      style={{
        backgroundColor: "black",
      }}
    >
      <button
        className="enter-button-aros center"
        type="button"
        onClick={() => {
          props.onClick();
        }}
      >
        Press Start to talk to the AI.
      </button>
    </div>
  );
}
